import { css } from '@emotion/react';
import { breakpoints, colors, spacing } from '../../utils/styleguide';

type DividerProps = {
  color?: string;
  hideOnDesktop?: boolean;
  hideOnSmallDevice?: boolean;
  vertical?: boolean;
};

const Divider = ({
  color = colors.lightGray,
  hideOnDesktop = false,
  hideOnSmallDevice = false,
  vertical = false,
  ...other
}: DividerProps) => {
  return (
    <div
      css={css`
        ${vertical ? 'width: 1px;' : 'height: 1px;'}
        margin: ${vertical ? `0 ${spacing[3]}px` : `${spacing[3]}px 0`};
        background-color: ${color};
        display: ${hideOnSmallDevice ? 'none' : 'block'};

        ${breakpoints.desktop} {
          display: ${hideOnDesktop ? 'none' : 'block'};
        }
      `}
      {...other}
    />
  );
};

export default Divider;
