import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeFormItemSkeleton } from './TypeFormItem';

export interface TypeFormFields {
  identifier: EntryFieldTypes.Symbol;
  type: EntryFieldTypes.Symbol<'hubspot'>;
  url: EntryFieldTypes.Symbol;
  submitText?: EntryFieldTypes.Symbol;
  successMessage?: EntryFieldTypes.Symbol;
  errorMessage?: EntryFieldTypes.Symbol;
  prompt?: EntryFieldTypes.Symbol;
  formItems: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeFormItemSkeleton>>;
  formTitle?: EntryFieldTypes.Symbol;
  formDescription?: EntryFieldTypes.RichText;
}

export type TypeFormSkeleton = EntrySkeletonType<TypeFormFields, 'form'>;
export type TypeForm<
  Modifiers extends ChainModifiers = 'WITHOUT_UNRESOLVABLE_LINKS',
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeFormSkeleton, Modifiers, Locales>;

export function isTypeForm<Modifiers extends ChainModifiers, Locales extends LocaleCode>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeForm<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'form';
}
