import styled from '@emotion/styled';
import { colors, spacing } from '../../../utils/styleguide';
import Slider from 'react-slick';
import { RefObject } from 'react';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import Typography from '../text/Typography';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
`;

type Props = {
  sliderRef: RefObject<Slider>;
  pageCount: number;
  currentPage: number;
  className?: string;
};

export default function CarouselStepper({ sliderRef, pageCount, currentPage, className }: Props) {
  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  return (
    <div
      className={className}
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        gap: `${spacing[1]}px`,
      }}
    >
      <StyledButton onClick={previous} aria-label={'prev'} disabled={currentPage === 1}>
        <ChevronLeft size={24} />
      </StyledButton>
      <Typography
        variant="overline"
        color={colors.blackDisabled}
      >{`${currentPage} / ${pageCount}`}</Typography>
      <StyledButton onClick={next} aria-label={'prev'} disabled={currentPage === pageCount}>
        <ChevronRight size={24} />
      </StyledButton>
    </div>
  );
}
