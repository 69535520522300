import { TypeLink } from '../../../@types/generated/TypeLink';
import { hasValue } from '../typeCheck';

export function linksToButtons(links?: Array<TypeLink | undefined>) {
  return links?.filter(hasValue).map(toButton);
}

function toButton(link: TypeLink): { label?: string; href?: string } {
  return {
    label: link.fields.identifier,
    href: link.fields.externalUrl,
  };
}
