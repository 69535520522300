import styled from '@emotion/styled';
import Typography from '../text/Typography';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import PrimaryButton from '../buttons/PrimaryButton';
import Container from './Container';

const Wrapper = styled.div<{ hasBackgroundImage: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: ${({ hasBackgroundImage }) =>
    hasBackgroundImage ? undefined : colors.purple100};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[2]}px;

  ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export default function CTASectionLayout({
  sectionTitle,
  title,
  buttons,
  backgroundImageUrl,
  backgroundImageAlt,
  id,
}: {
  sectionTitle?: string;
  title?: string;
  buttons?: {
    label?: string;
    href?: string;
  }[];
  backgroundImageUrl?: string;
  backgroundImageAlt?: string;
  id?: string;
}) {
  if (!sectionTitle && !title && !buttons && !backgroundImageUrl) return null;

  return (
    <Container
      id={id}
      lazyLoad
      backgroundImage={backgroundImageUrl}
      backgroundImageAlt={backgroundImageAlt}
    >
      <Wrapper hasBackgroundImage={!!backgroundImageUrl}>
        {sectionTitle && (
          <Typography
            variant="overline"
            color={colors.whiteSecondary}
            style={{ marginBottom: `${spacing[2]}px` }}
          >
            {sectionTitle}
          </Typography>
        )}

        {title && (
          <Typography
            variant="h3"
            color={colors.whitePrimary}
            style={{ marginBottom: `${spacing[5]}px` }}
          >
            {title}
          </Typography>
        )}

        {!!buttons?.length && (
          <ButtonWrapper>
            {buttons?.map((button, index) => (
              <PrimaryButton
                key={index}
                color={index === 0 ? 'purple' : 'white'}
                label={button.label}
                variant={index === 0 ? 'solid' : 'outline'}
                href={button.href}
                openLinkInNewTab={
                  !(button.href?.includes('milkywire.com') || button.href?.startsWith('/'))
                }
              />
            ))}
          </ButtonWrapper>
        )}
      </Wrapper>
    </Container>
  );
}
